$dot-diameter: 10px;

.dot {
  width: $dot-diameter;
  height: $dot-diameter;
  border-radius: 50%;
  margin: auto;

  &-red {
    @extend .dot;
    background-color: red;
  }

  &-green {
    @extend .dot;
    background-color: green;
  }

  &-grey {
    @extend .dot;
    background-color: grey;
  }
}
