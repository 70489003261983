@use "../../../styles/mixins.scss" as *;

.left-summary-col {
  text-align: right;
  padding-right: 2rem;

  @include sm {
    text-align: left;
    padding-right: 1rem;
  }
}

.right-summary-col {
  padding-left: 2rem;
  align-self: end;

  @include sm {
    padding-left: 0;
    text-align: left;
  }
}

.right-summary-col-text {
  color: var(--font-color);

  @include sm {
    color: var(--primary-color);
  }
}
