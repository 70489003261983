@use "../../styles/mixins.scss" as *;

.form-summary-container {
  overflow-wrap: break-word;
}

.consent-checkbox-link {
  color: var(--interactive-button-color);

  :hover {
    color: var(--interactive-button-color-brighter);
  }
}

.consent-checkbox-container {
  padding-top: 0;
  padding-bottom: 2rem;

  @include sm {
    padding: 0.5rem 0;
    padding-bottom: 1rem;
  }

  > .ant-checkbox-wrapper > .ant-checkbox {
    align-self: flex-start;
    margin-top: 3px;
  }
}

.base-rate-summary-container > .ant-card > .ant-card-body {
  background-color: var(--base-rate-summary-card-color);
}

.base-rate-summary-card-title {
  @include sm {
    font-size: 14px;
  }
}
