// breakpoints
$breakpoint-s-width: 575px;
$breakpoint-m-width: 768px;
$breakpoint-l-width: 992px;
$breakpoint-xl-width: 1200px;
$breakpoint-xxl-width: 1400px;

@mixin sm {
  @media screen and (max-width: $breakpoint-s-width) {
    @content;
  }
}

@mixin md {
  @media screen and (max-width: $breakpoint-m-width) {
    @content;
  }
}

@mixin lg {
  @media screen and (max-width: $breakpoint-l-width) {
    @content;
  }
}

@mixin xl {
  @media screen and (max-width: $breakpoint-xl-width) {
    @content;
  }
}

@mixin xxl {
  @media screen and (max-width: $breakpoint-xxl-width) {
    @content;
  }
}

@mixin smallerthan($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}
