.load-profile-type__button {
  white-space: normal;
  height: 5rem;
  width: 6rem;
  margin: 8px;
  border-color: white;
  box-shadow: none;
  border-radius: 6px !important;
  padding: 4px;

  &--checked {
    border-color: var(--primary-color);
  }

  .label {
    width: 100%;
  }
}
