@use "../../../../styles/mixins.scss" as *;

.close-outlined-icon {
  color: rgb(0 0 0 / 25%);
}

.detail-card-title {
  color: var(--primary-color);
}

.rate-specifications-card-body {
  overflow-y: auto;
  max-height: 82.5vh;
  display: flex;
  flex-wrap: wrap;
  color: var(--font-color);

  @include md {
    max-height: 80vh;

    p {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
}

.rate-specifications-card-body-row-left {
  float: left;
  width: 50%;
  padding-right: 24px;

  @include lg {
    width: 100%;
    padding: 0;
  }
}

.rate-specifications-card-body-row-right {
  float: left;
  width: 50%;
  padding-left: 24px;

  @include lg {
    width: 100%;
    padding: 0;
  }
}

.rate-specifications-card-body-col {
  float: left;
  width: 100%;
  display: flex;

  @include lg {
    padding: 2px 0;
  }
}

.rate-specifications-card-col-left {
  text-align: left;
  float: left;
  width: 50%;
}

.rate-specifications-card-col-right {
  text-align: right;
  width: 50%;
  float: right;
  align-self: end;
}

.rate-specifications-card-note {
  width: 100%;
  padding: 0 24px 8px;
  font-size: 12px;

  @include md {
    font-size: 10px;
  }
}

.rate-specifications-card-terms {
  padding: 8px 24px;
  text-align: center;

  @include md {
    padding: 24px 8px 0;
    margin: 0;
  }
}
