.page-container {
  padding-left: 32px;
  padding-right: 32px;
  width: 90vw;
}

.page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.page-subtitle {
  color: var(--text-weak-color);
  font-size: 0.85em;
  margin-left: 16px;
}

.back-button {
  margin-right: 16px;
}

.content-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
