@use "../../styles/mixins.scss" as *;

.order-confirmation-card {
  border-color: var(--border-color);
  box-shadow: 0 2px 8px 0 rgba(0 0 0 / 15%);
  color: var(--font-color);
  max-width: 800px;
  padding: 48px;
  margin: 0 48px;

  @include md {
    border-color: transparent;
    box-shadow: none;
    margin: 0;
    padding: 24px 0;
  }
}

.heading-col {
  align-items: center;
  display: flex;

  @include md {
    justify-content: center;
  }
}

.final-check-icon-col {
  align-items: center;
  display: flex;
  justify-content: center;
}

.final-check-icon {
  color: var(--primary-color);
  width: 100%;

  svg {
    height: 100%;
    width: 100%;
  }

  @include md {
    width: 45%;
  }
}

.order-confirmation-heading {
  font-size: 30px;
  font-weight: 500;

  @include md {
    text-align: center;
  }
}

.order-confirmation-subheading {
  @include md {
    text-align: center;
  }
}

.order-confirmation-text {
  margin-top: 48px;
  text-align: left;

  @include md {
    text-align: center;
  }
}
