@use "../../../styles/mixins.scss" as *;

.text-block-row {
  padding: 0.5rem 0;
}

.left-text-block-col {
  text-align: right;
  padding-right: 0.5rem;

  @include sm {
    text-align: center;
    padding-right: 0;
  }
}

.right-text-block-col {
  display: flex;
  padding-left: 0.5rem;

  @include sm {
    padding-left: 0;
    text-align: center;
    justify-content: center;
  }
}

.right-text-block-col-first-text {
  color: var(--primary-color);
}

.right-text-block-col-second-text {
  color: var(--font-color);
  opacity: 0.3;
  padding-left: 0.25rem;
}

.previous-order-modal__content .ant-modal-content {
  padding: 35px 70px 50px;

  .ant-modal-header {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
  }

  .ant-modal-body {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;
    min-height: 225px;
  }

  .ant-modal-footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex-direction: column;
    gap: 20px;
  }

  .previous-order-modal__button {
    width: 400px;
    min-height: 40px;
    margin: 0 !important;

    @include sm {
      width: 100%;
      white-space: normal;
      height: 55px;
    }
  }
}
