p {
  margin-top: 10px;
  margin-bottom: 10px;
}

label {
  margin-top: 5px;
  margin-bottom: 5px;
}

.offer-label {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 5px;
}

.big-price-value {
  font-size: 30px;
  font-weight: bold;
  padding-right: 10px;
}

.big-price-label {
  font-size: 14px;
  color: rgb(0 0 0 / 25%);
  padding-top: 8px;
}

.small-price {
  color: rgb(0 0 0 / 25%);
}

.offer-card {
  width: 100%;
  border-radius: 8px;

  box-shadow: 0 2px 8px 0 rgba(0 0 0 / 15%);
  color: var(--font-color);
  background-color: var(--base-rate-card-background-color);
}

.offers-values {
  text-align: right;
}

.offers-labels {
  text-align: left;
}

.offer-buttons {
  padding-top: 24px;
}

.button-more-details {
  width: 100%;
  color: var(--interaction-color);
  height: 40px;

  :hover {
    color: var(--interaction-color) !important;
    opacity: 0.7;
  }
}

.offer-buttons .button-select-offer {
  width: 100%;
  height: 40px;

  > span {
    color: var(--select-base-rate-button-text-color);

    :hover {
      color: var(--select-base-rate-button-text-color);
    }
  }
}

.footer-check-icon {
  text-align: right;
  padding-right: 10px;
  margin: 5px;
}

.check-icon {
  color: var(--primary-color);
  font-size: 25px;
}

.info-icon {
  font-size: 20px;
  text-align: center;
  color: var(--non-prominent-price-color);
}

.info-text {
  font-size: 12px;
}

.prominent-price-color {
  color: var(--prominent-price-color);
}

.non-prominent-price-color {
  color: var(--non-prominent-price-color);
}

.offer-card-divider {
  margin: 7px 0 10px;
}

.dynamic-card {
  width: 100%;
  border-radius: 8px;

  box-shadow: 0 2px 8px 0 rgba(0 0 0 / 15%);
  color: var(--font-color);
  background-color: var(--base-rate-card-background-color);
}

.ant-card-hoverable {
  cursor: default;
}
