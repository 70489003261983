@use "../../styles/mixins.scss" as *;

.heading {
  font-size: min(1.875em, 30px);
  font-weight: 500;
  line-height: 40px;
  color: var(--heading-color);

  @include md {
    font-size: 20px;
    line-height: 1.25;
  }
}

.subheading {
  font-size: min(0.875em, 14px);
  line-height: 22px;
  margin-bottom: 20px;

  @include md {
    padding-top: 8px;
  }
}

.text-align-center {
  text-align: center;
}

.no-base-rates-text {
  color: red;
  font-size: min(1.25em, 20px);
  font-weight: bold;
}

.loading-text {
  font-size: min(1em, 16px);
  margin-bottom: 20px;

  @include md {
    padding-top: 8px;
  }
}

.base-rate-overview-form {
  max-width: 600px;
}

.show-base-rates-button:not(.show-base-rates-button:disabled) > span {
  color: var(--interactive-button-text-color);

  :hover {
    color: var(--interactive-button-text-color);
  }
}

.button-full-width {
  width: 100%;
  margin-top: 2em;
}

.offers {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button-icon-edit {
  padding: 4px;
}

.button-icon-edit:hover {
  opacity: 0.7;
}

.primary-color,
.edit-outlined-icon {
  color: var(--primary-color);
}

.order-route {
  max-width: 900px;
  width: 90%;
  margin-bottom: 50px;
}

.mobile-step-number {
  font-size: 1rem;
}

.mobile-step-container {
  margin-bottom: 40px;
  display: flex;
  margin-top: 40px;
  border-bottom: 2px solid var(--primary-color);
  width: 100%;
  justify-content: center;
}

.mobile-step-content {
  margin-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.mobile-step-description {
  font-size: min(0.875em, 14px);
  margin-left: 0.5rem;
}

.mobile-step-current {
  opacity: 0.5;
}

.step-container {
  max-width: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.final-screen-container,
.consumption-and-zip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-bar {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  margin: 0;

  > * {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @include sm {
    flex-flow: column wrap;
    flex-direction: column-reverse;
    margin: 0;

    > * {
      &:not(:first-child) {
        margin-bottom: 16px;
      }

      &:first-child {
        margin-right: 0;
      }
    }
  }
}

.final-button-bar {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
  margin: 0;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  @include sm {
    flex-flow: column wrap;
    flex-direction: column-reverse;
    margin: 0;

    > * {
      &:not(:first-child) {
        margin-bottom: 16px;
      }

      &:first-child {
        margin-right: 0;
      }
    }
  }
}

.button-nav-prev {
  margin: 0;
  padding-left: 30px;
  padding-right: 30px;
}

.button-nav-next,
%button-nav-next {
  padding-left: 30px;
  padding-right: 30px;

  &-final {
    @extend %button-nav-next;
    background-color: var(--finalise-order-button-color);
  }
}

p[class*="-label"],
label[class*="-label"] {
  color: var(--input-label-color);
}

.input-label {
  // TODO CT: Check if this is really applied rather than the label color from the frontend config
  color: var(--font-color-light);
}

.step-title {
  margin-bottom: 40px;

  @include md {
    margin-bottom: 20px;
  }

  h2 {
    display: flex;
    flex-direction: row;
    font-size: 1.5em;
    font-weight: bolder;

    color: var(--primary-color);

    &::before {
      content: "";
      flex: 0.05;
      border-bottom: 1.5px solid;
      border-color: var(--primary-color);
      margin: 18px 10px auto auto;
    }

    &::after {
      content: "";
      flex: 1;
      border-bottom: 1.5px solid;
      border-color: var(--primary-color);
      margin: 18px auto auto 10px;
    }
  }
}

.input-row {
  padding-bottom: 26px;
}

.input-title {
  padding-bottom: 6px;
}

.input-error {
  background-color: #ffccc7;

  > input {
    background-color: #ffccc7;
    color: #cf1322;
  }
}

.radio-label {
  color: var(--font-color) !important;
}

.consent-checkbox-text {
  color: var(--font-color);
  padding-left: 1rem;
}

.radio-box {
  margin-top: 30px;
}

[class*="error-date"] {
  width: 100%;
  background-color: #ffccc7 !important;

  .ant-picker-clear {
    background-color: #ffccc7;
  }

  .ant-picker-input > input {
    color: #cf1322;
  }
}

[class*="valid-date"] {
  width: 100%;
}

input:-webkit-autofill:not(.zipcode-input-valid, .input-valid) {
  box-shadow: 0 0 0 30px #ffccc7 inset;
  -webkit-text-fill-color: #cf1322;
  transition: background-color 600000s 0s;
}

input:-webkit-autofill:not(
    .zipcode-input-error,
    .input-error,
    .input-error > input
  ),
input:-webkit-autofill:focus:not(
    .zipcode-input-error,
    .input-error,
    .input-error > input
  ) {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black;
}
