.dynamic-offers-col .button-select-offer {
  margin: 40px 0;
  width: 347px;
  height: 40px;
}

.dynamic-offer-disclaimer {
  margin-bottom: 20px;
}

.mobile-dynamic-offer-disclaimer {
  max-width: 347px;
  margin: 15px 0 40px;
}

.mobile-dynamic-offer-wrapper {
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.desktop-dynamic-offer-wrapper {
  margin: 20px;
  gap: 15px;
  flex-flow: row;
}

.desktop-dynamic-offer-row-wrapper {
  flex-direction: row-reverse;
  justify-content: end;
}

.dynamic-offers-container-mobile {
  margin: auto;
}

.dynamic-offers-container-desktop {
  max-width: 1028px;
  margin: auto;
}

.dynamic-offer-buttons {
  display: flex;
}

.desktop-dynamic-offer-row-wrapper .button-back {
  margin-right: 8px;
}

.mobile-dynamic-offer-row-wrapper .button-back {
  margin: 8px 0;
  width: 347px;
  height: 40px;
  flex: 0 0 347px;
}

.mobile-dynamic-offer-row-wrapper .button-select-offer {
  width: 347px;
  height: 40px;
  flex: 0 0 347px;
}

.dynamic-offer-price-content-column {
  display: flex;
  justify-content: center;
  max-width: 685px;
  width: auto;
  min-width: 345px;
}

.mobile-dynamic-offer-card-wrapper {
  max-width: 347px;
}

.desktop-dynamic-offer-footer-row {
  flex-flow: row;
  justify-content: space-between;
  margin: 40px 20px;
}

.mobile-dynamic-offer-footer-row {
  margin-bottom: 40px;
}

.mobile-offer-button-col {
  width: 100%;
  display: flex;
  justify-content: center;
}
