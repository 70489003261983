.load-profile__title {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.load-profile-label {
  padding-right: 0.5rem;
}

.label {
  width: 55px;
  height: 28px;
  font-size: 12px;
  line-height: 1;
  color: var(--font-color-light);
}
