@use "../../../styles/mixins.scss" as *;

.base-rate-form-card {
  width: 80%;
  max-width: 35em;
  overflow: hidden;
  margin-top: 4em;
  padding: 1.5em;

  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);
  background-color: var(--base-rate-overview-background-color);

  @include md {
    background-color: transparent;
    box-shadow: none;
    margin-top: 0;
    padding: 0;
    width: 95%;
    border: none;
  }
}

.base-rate-form-head {
  text-align: center;
  font-size: 16px;
  margin-bottom: 2em;

  color: var(--font-color);

  @include md {
    font-size: 14px;
  }
}
