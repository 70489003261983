@use "../../../styles/colors";

.react-datepicker {
  border-color: var(--border-color);
  box-shadow:
    0 6px 16px 0 rgba(0 0 0 / 8%),
    0 3px 6px -4px rgba(0 0 0 / 12%),
    0 9px 28px 8px rgba(0 0 0 / 5%);
}

.react-datepicker-wrapper {
  width: 100%;
  font-family: inherit;
}

.react-datepicker__input-container {
  width: 100%;
  position: relative;
  display: inline-flex;
  min-width: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5714;
  background-color: #fff;
  background-image: none;
  transition: all 0.2s;
  font-family: inherit;
}

.datepicker__input {
  padding: 6px 11px;
  width: 100%;
  font-size: inherit;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  outline: none;
  font-family: inherit;
  color: rgba(0 0 0 / 80%);

  ::placeholder {
    color: rgba(0 0 0 / 50%);
  }
}

.datepicker__input--invalid {
  padding: 6px 11px;
  width: 100%;
  font-size: inherit;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  outline: none;
  font-family: inherit;
  border-color: var(--danger-color);
  background-color: var(--danger-color-light);
  color: rgba(0 0 0 / 80%);

  ::placeholder {
    color: rgba(0 0 0 / 50%);
  }
}

.react-datepicker__calendar-icon {
  right: 0;

  svg {
    color: rgba(0 0 0 / 50%);
  }
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 11px;
}

.react-datepicker__day--disabled {
  pointer-events: none;
  background-color: var(--border-color);
  border-radius: 30%;
}

.react-datepicker__header {
  background-color: var(--background-color);
  border-bottom: none;
  padding-bottom: 0;
}

.react-datepicker__today-button {
  background-color: var(--background-color);
  border-color: var(--border-color);
  border-radius: 0 0 0.3rem 0.3rem;
}

.react-datepicker__header-upper-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  padding: 5px;
  border-bottom: 1px solid var(--border-color);
}

.react-datepicker__header-nav-button {
  color: rgba(0 0 0 / 50%);
  padding: 3px;
}

.react-datepicker__header-month,
.react-datepicker__header-year {
  font-weight: bold;
  font-size: 0.95rem;
}

.react-datepicker__close-icon::after {
  background-color: #a3a3a3;
  opacity: 1;
  height: 15px;
  width: 15px;
}

.react-datepicker__day--outside-month:not(.react-datepicker__day--disabled) {
  color: rgba(0 0 0 / 50%);
}
