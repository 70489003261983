.dynamic-price-content-header {
  justify-content: space-between;
  margin: 35px 0;
  align-items: center;
}

.dynamic-price-content-wrapper {
  width: 100%;
}

.dynamic-price-table .ant-table-thead .ant-table-cell {
  background-color: transparent !important;
}

.dynamic-price-table-card {
  margin-top: 24px;
  box-shadow: 0 2px 8px 0 rgba(0 0 0 / 15%);
  border-radius: 8px;
}

.dynamic-price-table-card .ant-table {
  border-radius: 8px;
}

.dynamic-price-table-card .ant-card-body {
  padding: 0;
}

.dynamic-price-table-content-mobile {
  max-width: 347px;
}

.dynamic-price-content-header-divider {
  height: 3rem;

  @media screen and (max-width: 868px) {
    display: none;
  }
}

.dynamic-price-table ::-webkit-scrollbar {
  width: 4px;
}

.dynamic-price-table ::-webkit-scrollbar-track {
  background: transparent;
}

.dynamic-price-table ::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px;
}

.dynamic-price-toggle-row {
  display: flex;
  justify-content: flex-end;
}
