@use "../../../styles/mixins.scss" as *;

.base-rate-type__title {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

// unfortunately we can't use BEM here, because Ant D scans for class names ending on "-label" to apply styles
.base-rate-type-label {
  padding-right: 0.5rem;
}

.base-rate-type__radio-group {
  width: 100%;
}

.base-rate-type__radio-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.4rem !important;
  height: 3rem !important;

  @include md {
    font-size: 12px;
  }
}
