@use "../../../styles/mixins.scss" as *;

.offers-fieldset {
  // These are "default" colors and may be overwritten by configs specified in the rates
  border-color: var(--primary-color);
  color: var(--primary-color);

  border-radius: 8px;
  padding: 0;
}

.offers-legend {
  // These are "default" colors and may be overwritten by configs specified in the rates
  background-color: var(--primary-color);
  color: var(--background-color);

  position: absolute;
  right: -8px;
  top: -10px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: right;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  z-index: 7;
}

.shadow-legend {
  width: 10px;
  height: 5px;
  background:
    linear-gradient(to top right, transparent 49%, transparent 50%) top right,
    linear-gradient(to top left, transparent 49%, transparent 50%) top left,
    linear-gradient(to bottom right, rgba(0 0 0 / 25%) 49%, transparent 50%)
      bottom right,
    linear-gradient(to bottom left, transparent 49%, transparent 50%) bottom
      left;
  position: absolute;
  right: 0;
  top: 22px;
}

.row-button-back {
  float: right;
  text-align: right;
  padding-top: 40px;

  @include sm {
    width: 100%;
  }
}

.button-back {
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;

  @include sm {
    width: 100%;
  }
}
