.input-label-light {
  color: rgb(0 0 0 / 25%);
}

.gender-input-error {
  .ant-select-selector {
    background-color: #ffccc7 !important;
    color: #cf1322;
  }

  .ant-select-clear {
    background-color: #ffccc7;
  }
}

.ant-picker-cell-gray {
  background: rgba(0 0 0 / 4%);
  color: rgba(0 0 0 / 25%);
  pointer-events: none;
}
