.inner-table thead {
  visibility: collapse;
}

.inner-table .ant-table-cell {
  background-color: #fafafa;
}

.inner-table {
  max-height: 550px; // 10 Table cells
  overflow: hidden auto;
}

.rate-specification-table .ant-table-cell {
  padding: 16px !important; // Can't believe this seems to be the way to do it in the antd table :D
}
