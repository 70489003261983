:root {
  // Fallback color values

  // Base colors
  --primary-color: #1890ff;
  --primary-highlight-color: #1890ff20;
  --interaction-color: #702cd7;
  --background-color: #fff;
  --font-color: rgb(0 0 0 / 85%);
  --font-color-light: rgb(0 0 0 / 45%);
  --border-color: #f0f0f0;
  --danger-color: #e85f61;
  --danger-color-light: #ffccc7;
  --input-label-color: var(--font-color);
  --question-circle-color: var(--primary-color);
  --interactive-button-color: var(--primary-color);
  --interactive-button-text-color: var(--interaction-color);

  // BaseRate Overview colors
  --heading-color: var(--font-color);
  --base-rate-overview-background-color: var(--background-color);

  // BaseRate Offers colors
  --base-rate-card-background-color: var(--background-color);
  --prominent-price-color: var(--font-color);
  --non-prominent-price-color: var(--font-color);
  --select-base-rate-button-color: var(--primary-color);
  --select-base-rate-button-text-color: var(--interaction-color);

  // BaseRate Summary colors
  --finalise-order-button-color: var(--interaction-color);
  --base-rate-summary-card-color: var(--primary-highlight-color);
}
