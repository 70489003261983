@use "../../styles/mixins.scss" as *;

.input-col-left {
  padding-right: 8px;
}

.input-col-previous-provider {
  padding-right: 8px;

  @include md {
    padding-right: 0;
  }
}
