@use "../../../../styles/mixins.scss" as *;

.number-of-people {
  color: var(--font-color-light);
}

.person-icon {
  font-size: 16px;
  cursor: pointer;

  @include smallerthan("500px") {
    font-size: 12px;
  }
}

.first-persons {
  text-align: center;
  padding-right: 1rem;
}

.second-persons {
  text-align: center;
}

.third-persons {
  text-align: center;
}

.fourth-persons {
  text-align: center;
  padding-left: 2rem;
}
