.footnote p {
  color: var(--non-prominent-price-color);
  font-size: 9px;
  margin: 0;
}

.footnote p:first-child {
  margin-right: 5px;
}

.footnote {
  display: flex;
  justify-content: center;
  justify-items: center;
}
