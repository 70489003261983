:root {
  // Fallback color values
  --primary-color: #1890ff;

  --background-color: #fff;
  --font-color: rgb(0 0 0 / 85%);

  --text-weak-color: #656565; // TODO use real color

  --border-color: #f0f0f0;
}

.primary-color {
  color: var(--primary-color);
}

.tenant-app-content {
  background-color: var(--background-color);

  display: flex;
  flex-direction: column;
  align-items: center;
}
