.spinner {
  display: flex;
  justify-content: center;
}

.row {
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
}

.header-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #fafafa;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.cell {
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  display: flex;
  align-items: center;
}

.value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.name {
  background-color: #fafafa;
}

.heading-info-circle-outlined {
  padding-left: 8px;
  color: rgba(0 0 0 / 45%);
}
