.active-toggle {
  background: var(--primary-color);

  > span {
    color: var(--select-base-rate-button-text-color);

    :hover {
      color: var(--select-base-rate-button-text-color);
    }
  }
}

.text-toggle-both-sides {
  border: solid 2px var(--primary-color);
  border-radius: 20px;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  color: var(--primary-color);
  width: 330px;
}

.text-toggle-one-side {
  border-radius: 20px;
  cursor: pointer;
  width: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  text-align: center;
  height: inherit;
  align-items: center;
}

.text-toggle-left-side.active-toggle::before {
  background-color: var(--primary-color);
  content: "";
  margin: 0 auto;
  position: absolute;
  left: -1px;
  top: -1px;
  width: 100%;
  height: calc(100% + 2px);
  border-radius: 20px;
  padding: 3px 0;
  border: 2px solid var(--primary-color);
  z-index: -1;
}

.text-toggle-right-side.active-toggle::before {
  background-color: var(--primary-color);
  content: "";
  margin: 0 auto;
  position: absolute;
  right: -1px;
  top: -1px;
  width: 50%;
  height: calc(100% + 2px);
  border-radius: 20px;
  padding: 3px 0;
  border: 2px solid var(--primary-color);
  z-index: -1;
}

.icon-toggle-both-sides {
  border: solid 1px var(--primary-color);
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  color: var(--primary-color);
}

.icon-toggle-one-side {
  cursor: pointer;
  width: 50%;
  padding: 0 9px;
}
