@use "../../../../../styles/mixins.scss" as *;

.lighter-font-color {
  color: var(--font-color-lighter);
}

.rate-specifications-card-energymix {
  width: 100%;
  padding: 24px 24px 0;

  @include lg {
    padding-top: 0;
  }
}

.rate-specifications-card-energymix-extra {
  width: 100%;
  padding: 0 24px 24px;
}
