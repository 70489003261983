@keyframes slide-in {
  0% {
    transform: translateX(2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.ant-carousel {
  display: flex;
  justify-content: center;
  animation: slide-in 500ms forwards;
}

.card-container {
  width: 347px;
  margin: 0.781em;
}

.card-container-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.781em;
}

.offer-dots {
  bottom: -2rem !important;

  li > button {
    background: var(--select-base-rate-button-color) !important;
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  left: 0.2rem;
  z-index: 2;
  width: 30px;
  background-color: var(--swipe-button-color);
  color: white;
  border-radius: 16px;
  font-size: 20px;
  height: 30px;
  display: flex !important;
  justify-content: center;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  right: 0.2rem;
  z-index: 2;
  width: 30px;
  background-color: var(--swipe-button-color);
  color: white;
  border-radius: 16px;
  font-size: 20px;
  height: 30px;
  display: flex !important;
  justify-content: center;
}

.offer-carousel {
  width: 100%;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes fade-in {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.slick-slide.slick-active {
  transition: opacity 800ms;
}

.slick-slide:not(.slick-active) {
  opacity: 0.5;
  transition: opacity 800ms;
}

.slick-disabled {
  visibility: hidden;
  transition: visibility 400ms;
}
