.total-card {
  background-color: white;
  margin: 8px;
  padding: 10px;

  .input-error {
    background-color: white;
  }

  .error-message {
    position: absolute;
    bottom: -20px;
    left: 0;
    color: #cf1322;
  }

  .error-message-energymix {
    color: #cf1322;
    float: left;
  }

  .input-selector {
    text-align: center;

    .ant-select-selection-item {
      width: 75px;
    }
  }

  .input-addafter {
    text-align: center;
    font-size: 14px;
    margin: 0;

    width: 75px;
  }
}

.inner-card {
  margin-bottom: 32px;

  .ant-card-head {
    min-height: 38px;
    background-color: aliceblue;
  }
}

.save-btn {
  float: right;
  margin: 8px;
}

.bottom-save-btn {
  margin-right: 0;
}

.info-section {
  background-color: aliceblue;
  width: auto;
  margin-top: 12px;
  margin-bottom: 36px;
  padding: 8px;
}

.field {
  width: 100%;
}

.field-col {
  padding: 0 8px;

  .ant-form-item {
    margin-bottom: 28px;
  }
}
