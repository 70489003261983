.zip-label {
  color: var(--font-color-light);
  margin: 0;
  padding-bottom: 0.5em;
  background-color: transparent;
}

.zipcode-input-valid,
.zipcode-input-error {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.location-input-valid,
.location-input-error {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
}

.location-input-valid:hover,
.location-input-valid:focus-within {
  border-left-width: 1px;
}

.zipcode-input-error,
.location-input-error {
  background-color: #ffccc7;
  color: #cf1322;
  border-left-width: 1px;
}

.location-input-error .ant-input {
  background-color: #ffccc7;
  color: #cf1322;
}

.consumption-and-zip-location__input > .ant-input-disabled {
  color: var(--font-color) !important;
}

.error-message {
  color: #cf1322;
  float: left;
}

.ant-steps-item-description {
  font-size: 12px !important;
}

.zip-location__row {
  display: grid;
  grid-auto-flow: dense;

  grid-template-columns: 25% 75%;
}

.zipcode__col--error {
  grid-column: auto / span 2;
}

.zipcode__col {
  grid-column: 1;
}

.location__col {
  grid-column: 2;
}
