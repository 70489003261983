.primary-color {
  color: var(--primary-color);
}

.app {
  background-color: var(--background-color);
  color: var(--font-color);

  font-size: calc(10px + 2vmin);
}

.font-base-color {
  color: var(--font-color);
}

.app-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// Custom global styles
.question-circle-color {
  color: var(--question-circle-color);
}

.padding-left-05 {
  padding-left: 0.5em;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.padding-bottom-8 {
  padding-bottom: 8px;
}
