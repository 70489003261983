@use "../../styles/mixins.scss" as *;

.radio-padding {
  padding-bottom: 30px;
}

.mobile-extra-padding {
  @include md {
    padding-bottom: 24px;
  }
}
