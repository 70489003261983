.dynamic-price-header-title {
  font-size: 12px;
}

.dynamic-price-header-value {
  font-size: 24px;
  font-weight: 700;
  color: var(--prominent-price-color);

  @media screen and (max-width: 1015px) {
    font-size: 17px;
  }
}

.dynamic-price-header-unit {
  font-size: 12px;
}

.dynamic-price-header-value-highlighted {
  color: var(--primary-color);
}
