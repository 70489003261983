@use "../../../../styles/mixins.scss" as *;

.room-size {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .room-size-label {
    color: var(--primary-color);

    // needs to be set again
    &:hover {
      cursor: pointer;
    }

    @include smallerthan("400px") {
      font-size: x-small;
    }
  }

  .room-size-button {
    align-items: center;
    display: flex;
    flex-direction: column;
    background: none;
    border: none;
    padding: 0;
    font: inherit;

    &:hover {
      cursor: pointer;
    }
  }

  .room-size-icon {
    height: 55px;
    width: 55px;

    @include smallerthan("400px") {
      height: 35px;
      width: 35px;
    }
  }

  .room-size-color-wrapper {
    position: relative;
  }

  .room-size-blend-mode {
    background-color: var(--primary-color);
    position: absolute;
    mix-blend-mode: color;
    inset: 0;
  }
}
