.consumption-title {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.consumption-label {
  padding-right: 0.5em;
}

.consumption-slider {
  width: 100%;
}

.consumption-manually-input-valid,
.consumption-manually-input-error {
  width: 100%;
}

.consumption-manually-input-error {
  background-color: #ffccc7;
}

.consumption-manually-input-error .ant-input-number-input {
  color: #cf1322;
}

.error-message {
  color: #cf1322;
  float: left;
}

.room-sizes {
  padding-top: 0.8em;
  padding-bottom: 1em;
}

.consumption-slider-container {
  padding-top: 0.5em;
  padding-bottom: 1.875em;
}

.consumption-row {
  padding-top: 0.5rem;
}
